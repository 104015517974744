












































































































::v-deep .set-container {
    margin: 20px;
    background: #fff;
    height: calc(100% - 40px);
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    & > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.big-title {
    margin: 20px 20px 0;
    font-size: 20px;
    color: #000;
    font-weight: bold;
    line-height: 1;
}
.demo-ruleForm {
    margin: 20px;
}
